<template>
    <div class="btn-layout-preset" @click="currentLayoutPreset = layout.id" :class="{'selected': isSelected}">
        <b-img fluid :src="require(`@/assets/img/layout-presets/${type}-${layout.id}.svg`)"/>
        <i class="fas fa-check floating-check-mark ml-1" v-if="isSelected"/>
    </div>
</template>

<script>

export default {
    name: `btn-layout-preset`,
    props: {
        value: {type: String},
        type: {type: String},
        layout: {type: Object, required: true}
    },
    computed: {
        currentLayoutPreset: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        },
        isSelected() {
            return this.currentLayoutPreset === this.layout.id;
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-layout-preset {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 70px;
    margin: 3px;
    border-radius: var(--border-radius);
    border: 2px solid rgba(black, 0.2);
    cursor: pointer;

    .preview-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 6px;
        text-align: center;
    }

    .preview-button {
        border-radius: 8px;
        height: 8px;
        width: 24px;
    }

    &:hover {
        border-color: var(--accent-color);
    }

    &.selected {
        transform: scale(0.95);
        border-color: var(--accent-color);
    }
}
</style>
