<template>
    <properties-component v-bind="$props" @close="$emit('close')">
        <b-form-group label-cols="4">
            <template #label>
                <span v-tr>Color|Couleur</span>
                <btn-responsive-prop v-model="component" field="color"/>
            </template>
            <input-color v-model="component.mediaProxy.color" use-css-var/>
        </b-form-group>

        <b-form-group label-cols="4">
            <template #label>
                <span v-tr>Blur|Flou</span>
                <btn-responsive-prop v-model="component" field="blur"/>
            </template>
            <b-select v-model="component.mediaProxy.blur">
                <option value="0" v-tr>None|Sans</option>
                <option v-for="n in 100" :key="`width-${n}`" :value="`${n}px`">{{n}}px</option>
            </b-select>
        </b-form-group>

        <b-form-group label-cols="4">
            <template #label>
                <span v-tr>Spread|Taille</span>
                <btn-responsive-prop v-model="component" field="spread"/>
            </template>
            <b-select v-model="component.mediaProxy.spread">
                <option value="0" v-tr>None|Sans</option>
                <option v-for="n in 100" :key="`width-${n}`" :value="`${n}px`">{{n}}px</option>
            </b-select>
        </b-form-group>

        <b-row>
            <b-col cols="6">
                <b-form-group>
                    <template #label>
                        <span v-tr>Offset X|Position X</span>
                        <btn-responsive-prop v-model="component" field="offsetX" not-inline/>
                    </template>
                    <e-input type="number" v-model.number="component.mediaProxy.offsetX" :min="-99" :max="99" required state="disabled"/>
                    <span class="unit">px</span>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group>
                    <template #label>
                        <span v-tr>Offset Y|Position Y</span>
                        <btn-responsive-prop v-model="component" field="offsetY" not-inline/>
                    </template>
                    <e-input type="number" v-model.number="component.mediaProxy.offsetY" :min="-99" :max="99" required state="disabled"/>
                    <span class="unit">px</span>
                </b-form-group>
            </b-col>
        </b-row>

        <slot></slot>
    </properties-component>
</template>

<script>
import BtnResponsiveProp from "@/components/editor/properties/components/btn-responsive-prop.vue";
import PropertiesComponent from "@/components/editor/properties/components/properties-component.vue";
import InputColor from "@/components/editor/properties/inputs/input-color.vue";
import EInput from "../../../../../vue-components/components/e-input.vue";

// properties-component-shadow #hot-reload-debug
export default {
    name: `properties-component-shadow`,
    components: {EInput, BtnResponsiveProp, InputColor, PropertiesComponent},
    extends: PropertiesComponent
}
</script>

<style lang="scss" scoped>
</style>
