<template>
    <properties-component v-bind="$props" @close="$emit('close')">
        <b-form-group label-cols="4">
            <template #label>
                <span v-tr>Color|Couleur</span>
                <btn-responsive-prop v-model="component" field="color"/>
            </template>
            <input-color v-model="component.mediaProxy.color" use-css-var/>
        </b-form-group>

        <b-form-group label-cols="4">
            <template #label>
                <span v-tr>Width|Épaisseur</span>
                <btn-responsive-prop v-model="component" field="width"/>
            </template>
            <b-select v-model="component.mediaProxy.width">
                <option value="none" v-tr>None|Sans</option>
                <option v-for="n in 50" :key="`width-${n}`" :value="`${n}px`">{{n}}px</option>
            </b-select>
        </b-form-group>

        <slot></slot>
    </properties-component>
</template>

<script>
import BtnResponsiveProp from "@/components/editor/properties/components/btn-responsive-prop.vue";
import PropertiesComponent from "@/components/editor/properties/components/properties-component.vue";
import InputColor from "@/components/editor/properties/inputs/input-color.vue";

// properties-component-border #hot-reload-debug
export default {
    name: `properties-component-border`,
    components: {BtnResponsiveProp, InputColor, PropertiesComponent},
    extends: PropertiesComponent
}
</script>

<style lang="scss" scoped>
</style>
