<template>
    <div class="btn-layout-align" @click="currentAlign = align" :class="['align-' + align, {'selected': isSelected}]">
        <template v-if="align === 'inherit'">
            <span v-tr>Default|Défaut</span>
        </template>
        <template v-else>
            <div class="preview-button" style="width: 20px"/>
            <div class="preview-button" style="width: 40px"/>
            <div class="preview-button" style="width: 30px"/>
        </template>
        <i class="fas fa-check floating-check-mark ml-1" v-if="isSelected"/>
    </div>
</template>

<script>

export default {
    name: `btn-layout-align`,
    props: {
        value: {type: String},
        type: {type: String},
        align: {type: String, required: true}
    },
    computed: {
        currentAlign: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        },
        isSelected() {
            return this.currentAlign === this.align;
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-layout-align {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 36px;
    width: 70px;
    margin: 3px;
    background-color: white;
    border-radius: var(--border-radius);
    border: 2px solid rgba(black, 0.2);
    cursor: pointer;
    padding: 0 5px;
    vertical-align: top;

    .preview-button {
        border-radius: 8px;
        height: 4px;
        width: 24px;
        background-color: #999;

        & + .preview-button {
            margin-top: 3px;
        }
    }

    span {
        font-size: 10pt;
        color: #555;
    }

    &.align-left {
        align-items: start;
    }

    &.align-right {
        align-items: end;
    }

    &:hover {
        border-color: var(--accent-color);
    }

    &.selected {
        transform: scale(0.95);
        border-color: var(--accent-color);
    }
}
</style>
